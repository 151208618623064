import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  CanLoad,
  CanMatch,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable, map, take } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { AuthService, _isAuthenticated } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard
  implements
    CanActivate,
    CanActivateChild,
    CanDeactivate<unknown>,
    CanLoad,
    CanMatch
{
  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) {}
  userId: number | null = null;
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.authService.identityCheck();
    let url: string = state.url;
    if (!_isAuthenticated) {
      this.router.navigate(['login'], {
        queryParams: { returnUrl: state.url },
      });
    }
    return this.checkUserLogin(next);
  }
  currentRole:any[]=[]
  async checkUserLogin(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    var userId = localStorage.getItem('userId')
    this.userId = userId ? parseInt(userId) : null;
    // Kullanıcı rolleri ve modüllerini al
    const [userRoles, userModuls] = await Promise.all([
      [localStorage.getItem('userRole')],
      localStorage.getItem('modul').split(',').map((num: string) => +num)
    ]);
    const expectedRoles = route.data['roles'];
    const routeModul = route.data['modul'];
    // Yetkisiz kullanıcıyı ana sayfaya yönlendir
    if (!this.hasRequiredRole(userRoles, expectedRoles) || !this.hasRequiredModul(userModuls, routeModul)) {
      return this.router.createUrlTree(['/']);
    }
    return true;
  }

  // Kullanıcının gerekli rollerden birine sahip olup olmadığını kontrol eder
  private hasRequiredRole(userRoles: string[], expectedRoles?: string[]): boolean {
    if (!expectedRoles) return true; // Eğer belirli bir rol gerekmiyorsa geçişe izin ver
    return userRoles.some(role => expectedRoles.includes(role));
  }

  // Kullanıcının gerekli modüle sahip olup olmadığını kontrol eder
  private hasRequiredModul(userModuls: number[], routeModul?: number[]): boolean {
    if (!routeModul) return true; // Eğer belirli bir modül gerekmiyorsa geçişe izin ver
    return userModuls.some(modul => routeModul.includes(modul));
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }
  canMatch(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }
}
