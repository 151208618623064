import { Routes } from '@angular/router';
import { AuthGuard } from './common/guard/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/layouts/layouts.component').then(
        (c) => c.LayoutsComponent
      ),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./components/home/home.component').then(
            (c) => c.HomeComponent
          ),
      },
      {
        path: 'login',
        loadComponent: () =>
          import('./components/login/login.component').then(
            (c) => c.LoginComponent
          ),
      },

      {
        path: 'portfolios',
        loadComponent: () =>
          import('./components/portolios/portolios.component').then(
            (c) => c.PortoliosComponent
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'fund-creation',
        loadComponent: () =>
          import('./components/fund-creation/fund-creation.component').then(
            (c) => c.FundCreationComponent
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'option-creation',
        loadComponent: () =>
          import('./components/option-creation/option-creation.component').then(
            (c) => c.OptionCreationComponent
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'future-creation',
        loadComponent: () =>
          import('./components/future-creation/future-creation.component').then(
            (c) => c.FutureCreationComponent
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'bond-creation',
        loadComponent: () =>
          import('./components/bond/bond.component').then(
            (c) => c.BondComponent
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'criterion-definition',
        loadComponent: () =>
          import('./components/criterion-definition/criterion-definition.component').then(
            (c) => c.CriterionDefinitionComponent
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'varAnalysis',
        loadComponent: () =>
          import('./components/var-analysis/var-analysis.component').then(
            (c) => c.VarAnalysisComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici','Super Admin'], modul:[1] },
      },
      {
        path: 'varAnalysisSettings',
        loadComponent: () =>
          import(
            './components/var-analysis-settings/var-analysis-settings.component'
          ).then((c) => c.VarAnalysisSettingsComponent),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici','Super Admin'], modul:[1] },
      },
      {
        path: 'rmd-tracking',
        loadComponent: () =>
          import(
            './components/rmd-limit-tracking/rmd-limit-tracking.component'
          ).then((c) => c.RmdLimitTrackingComponent),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici','Super Admin','Gozlemleyici'], modul:[1] },
      },
      {
        path: 'internalAudit',
        loadComponent: () =>
          import('./components/internal-audit/internal-audit.component').then(
            (c) => c.InternalAuditComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici','Super Admin'] , modul:[2]},
      },
      {
        path: 'internal-audit-result',
        loadComponent: () =>
          import(
            './components/internal-audit-result/internal-audit-result.component'
          ).then((c) => c.InternalAuditResultComponent),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici', 'Gozlemleyici', 'Super Admin'], modul:[2] },
      },
      {
        path: 'stress-test',
        loadComponent: () =>
          import('./components/stress-test/stress-test.component').then(
            (c) => c.StressTestComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici','Super Admin'], modul:[2] },
      },
      {
        path: 'stress-test-result',
        loadComponent: () =>
          import(
            './components/stress-test-result/stress-test-result.component'
          ).then((c) => c.StressTestResultComponent),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici', 'Gozlemleyici','Super Admin'] , modul:[3]},
      },
      {
        path: 'risk-value',
        loadComponent: () =>
          import('./components/risk-value/risk-value.component').then(
            (c) => c.RiskValueComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici','Super Admin'], modul:[3] },
      },
      {
        path: 'risk-value-result',
        loadComponent: () =>
          import(
            './components/risk-value-result/risk-value-result.component'
          ).then((c) => c.RiskValueResultComponent),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici', 'Gozlemleyici','Super Admin'] , modul:[3]},
      },
      {
        path: 'liquidity-test',
        loadComponent: () =>
          import('./components/liquidity-test/liquidity-test.component').then(
            (c) => c.LiquidityTestComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici', 'Gozlemleyici','Super Admin'] , modul:[3]},
      },
      {
        path: 'liquidity-test-result',
        loadComponent: () =>
          import(
            './components/liquidity-test-result/liquidity-test-result.component'
          ).then((c) => c.LiquidityTestResultComponent),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici', 'Gozlemleyici','Super Admin'] , modul:[3]},
      },
      {
        path: 'reporting',
        loadComponent: () =>
          import('./components/reporting/reporting.component').then(
            (c) => c.ReportingComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici','Super Admin'] , modul:[1,2,3]},
      },
      {
        path: 'my-reports',
        loadComponent: () =>
          import(
            './components/reporting-result/reporting-result.component'
          ).then((c) => c.ReportingResultComponent),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici', 'Gozlemleyici','Super Admin'], modul:[1,2,3]},
      },
      {
        path: 'active-share',
        loadComponent: () =>
          import('./components/active-share/active-share.component').then(
            (c) => c.ActiveShareComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici','Super Admin'] , modul:[3]},
      },
      {
        path: 'active-share-result',
        loadComponent: () =>
          import(
            './components/active-share-result/active-share-result.component'
          ).then((c) => c.ActiveShareResultComponent),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici', 'Gozlemleyici','Super Admin'], modul:[3] },
      },
      {
        path: 'pdf-settings',
        loadComponent: () =>
          import('./components/pdf-settings/pdf-settings.component').then(
            (c) => c.PdfSettingsComponent
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'user-settings',
        loadComponent: () =>
          import('./components/user-settings/user-settings.component').then(
            (c) => c.UserSettingsComponent
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'ftp-settings',
        loadComponent: () =>
          import('./components/ftp-settings/ftp-settings.component').then(
            (c) => c.FtpSettingsComponent
          ),
        canActivate: [AuthGuard],
      },  {
        path: 'ftp-uploader',
        loadComponent: () =>
          import('./components/ftp-uploader/ftp-uploader.component').then(
            (c) => c.FtpUploaderComponent
          ),
        canActivate: [AuthGuard],
      },    {
        path: 'module-settings',
        loadComponent: () =>
          import('./components/module-settings/module-settings.component').then(
            (c) => c.ModuleSettingsComponent
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'var-analysis-result',
        loadComponent: () =>
          import(
            './components/var-analysis-results/var-analysis-results.component'
          ).then((c) => c.VarAnalysisResultsComponent),
          canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici', 'Gozlemleyici','Super Admin'], modul:[1]},
      },
      {
        path: 'backtest',
        loadComponent: () =>
          import('./components/backtest/backtest.component').then(
            (c) => c.BacktestComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici','Super Admin'] , modul: [1]},
      },
      {
        path: 'backtest-result',
        loadComponent: () =>
          import('./components/bactest-result/bactest-result.component').then(
            (c) => c.BactestResultComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici', 'Gozlemleyici','Super Admin'] , modul: [1]},
      },
      {
        path: 'leverage-analysis',
        loadComponent: () =>
          import('./components/leverage-analysis/leverage-analysis.component').then(
            (c) => c.LeverageAnalysisComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici','Super Admin'], modul: [1]},
      },
      {
        path: 'leverage-analysis-result',
        loadComponent: () =>
          import('./components/leverage-analysis-result/leverage-analysis-result.component').then(
            (c) => c.LeverageAnalysisResultComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici','Gozlemleyici','Super Admin'], modul: [1]},
      },
      {
        path: 'bond-analysis',
        loadComponent: () =>
          import('./components/bond-analysis/bond-analysis.component').then(
            (c) => c.BondAnalysisComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici','Super Admin']},
      },
      {
        path: 'concentration-risk',
        loadComponent: () =>
          import(
            './components/concentration-risk/concentration-risk.component'
          ).then((c) => c.ConcentrationRiskComponent),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici','Super Admin'] , modul:[3]},
      },
      {
        path: 'concentration-risk-result',
        loadComponent: () =>
          import(
            './components/concentration-risk-result/concentration-risk-result.component'
          ).then((c) => c.ConcentrationRiskResultComponent),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici', 'Gozlemleyici','Super Admin'], modul:[3] },
      },
      {
        path: 'incremental-var',
        loadComponent: () =>
          import('./components/incremental-var/incremental-var.component').then(
            (c) => c.IncrementalVarComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici','Super Admin'] , modul: [1]},
      },
      {
        path: 'incremental-var-result',
        loadComponent: () =>
          import(
            './components/incremental-var-result/incremental-var-result.component'
          ).then((c) => c.IncrementalVarResultComponent),
        canActivate: [AuthGuard],
        data: { roles: ['Admin', 'Hesaplayici', 'Gozlemleyici','Super Admin'], modul: [1] },
      },
    ],
  },
];
