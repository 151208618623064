import {
  RouteReuseStrategy,
  DetachedRouteHandle,
  ActivatedRouteSnapshot,
} from '@angular/router';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  handlers: { [key: string]: DetachedRouteHandle } = {};

  // Buraya korunacak sayfa adlarını ekleyin
  protectedRoutes: string[] = [
    'backtest',
    'varAnalysis',
    'internalAudit',
    'stress-test',
    'risk-value',
    'liquidity-test',
    'concentration-risk',
    'active-share',
  ];

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return this.protectedRoutes.includes(route.routeConfig?.path || '');
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (this.shouldDetach(route)) {
      this.handlers[this.getKey(route)] = handle;
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!this.handlers[this.getKey(route)];
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (
      !route.routeConfig ||
      route.routeConfig.loadChildren ||
      !this.shouldAttach(route)
    ) {
      return null;
    }
    return this.handlers[this.getKey(route)];
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  getKey(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map((r) => r.url.map((segment) => segment.toString()).join('/'))
      .join('/');
  }
}
