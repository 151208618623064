import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GenericHttpService {
  constructor(
    private _http: HttpClient,
    private _toastr: ToastrService,
    private _spinner: NgxSpinnerService,
    @Inject('baseUrl') private baseUrl: string
  ) {}
  async getReturn<T>(api: string, model: any) {
    this._spinner.show();
    const response = await firstValueFrom(
      this._http.get<T>(`${this.baseUrl}/${api}`, { params: model })
    );
    this._spinner.hide();
    return response;
  }
  async getWithoutParams<T>(api: string, callBack: (res: T) => void) {
    try {
      this._spinner.show();
      const res: T = await this._http
        .get<T>(`${this.baseUrl}/${api}`)
        .toPromise();
      callBack(res);
      this._spinner.hide();
    } catch (err) {
      if (err instanceof HttpErrorResponse) {
        // Handle HTTP errors
      } else {
        // Handle other errors
      }
    } finally {
      this._spinner.hide();
    }
  }
  async getWithoutParamsAndNotSpinner<T>(
    api: string,
    callBack: (res: T) => void
  ) {
    try {
      const res: T = await this._http
        .get<T>(`${this.baseUrl}/${api}`)
        .toPromise();
      callBack(res);
      this._spinner.hide();
    } catch (err) {
      if (err instanceof HttpErrorResponse) {
        // Handle HTTP errors
      } else {
        // Handle other errors
      }
    } finally {
      this._spinner.hide();
    }
  }

  get<T>(api: string, model: any, callBack: (res: T) => void) {
    this._spinner.show();
    this._http.get<T>(`${this.baseUrl}/${api}`, { params: model }).subscribe({
      next: (res: T) => {
        callBack(res);
        this._spinner.hide();
      },
      error: (err: HttpErrorResponse) => {
        this._toastr.error(err.error.Detail);
        this._spinner.hide();
      },
    });
  }
  postforExel<T>(api: string, model: any, callBack: (res: T) => void) {
    this._spinner.show();
    let headers = new HttpHeaders();
    headers = headers.delete('Content-Type');
    this._http
      .post<T>(`${this.baseUrl}/${api}`, model, { observe: 'response' })
      .subscribe({
        next: (response) => {
          if (response.status === 200) {
            callBack(response.body);
          } else {
            this._toastr.error(response.statusText);
          }
          // this._spinner.hide();
        },
        error: (error) => {
          if (error.error.Detail) {
            this._toastr.error(error.error.Detail);
          } else {
            this._toastr.error(error.error);
          }
          this._spinner.hide();
        },
      });
  }
  post<T>(api: string, model: any, callBack: (res: T) => void) {
    this._spinner.show();
    let headers = new HttpHeaders();
    headers = headers.delete('Content-Type');
    this._http
      .post<T>(`${this.baseUrl}/${api}`, model, { observe: 'response' })
      .subscribe({
        next: (response) => {
          if (response.status === 200) {
            callBack(response.body);
          } else {
            this._toastr.error(response.statusText);
          }
          this._spinner.hide();
        },
        error: (error) => {
          if (error.error.Detail) {
            this._toastr.error(error.error.Detail);
          } else {
            this._toastr.error(error.error);
          }
          this._spinner.hide();
        },
      });
  }
  postForReport<T>(api: string, model: any, callBack: (res: T) => void) {
    let headers = new HttpHeaders();
    headers = headers.delete('Content-Type');
    this._spinner.show();
    this._http
      .post<T>(`${this.baseUrl}/${api}`, model, { observe: 'response' })
      .subscribe({
        next: (response) => {

          if (response.status === 200) {
            callBack(response.body);
          } else {
            this._toastr.error(response.statusText);
          }
        },
        error: (error) => {
          if (error.error.Detail) {
            this._toastr.error(error.error.Detail);
          } else {
            this._toastr.error(error.error);
          }
        },
      });
  }
  postByTwoParameters<T>(
    api: string,
    model: any,
    reportKey: any,
    callBack: (res: T) => void
  ) {
    this._spinner.show();
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Report-Key', reportKey);
    this._http
      .post<T>(`${this.baseUrl}/${api}`, model, {
        headers: headers,
        observe: 'response',
      })
      .subscribe({
        next: (response) => {
          if (response.status === 200) {
            callBack(response.body);
          } else {
            this._toastr.error(response.statusText);
          }
          this._spinner.hide();
        },
        error: (error) => {
          this._toastr.error(error.error);
          this._spinner.hide();
        },
      });
  }
  delete<T>(api: string, callBack: (res: T) => void) {
    this._spinner.show();

    // Make the DELETE request without the 'Content-Type' header
    this._http
      .delete<T>(`${this.baseUrl}/${api}`, {
        headers: new HttpHeaders().delete('Content-Type'),
        observe: 'response',
      })
      .subscribe({
        next: (response) => {
          callBack(response.body);
          this._spinner.hide();
        },
        error: (error) => {
          this._toastr.error(error.error);
          this._spinner.hide();
        },
      });
  }
}
