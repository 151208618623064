import { bootstrapApplication } from "@angular/platform-browser";
import { provideHttpClient, withInterceptorsFromDi, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, enableProdMode } from "@angular/core";
import { AppComponent } from "./app/app.component";
import { environment } from "./environments/environment";
import { AuthInterceptor } from "./app/intercepter/auth.interceptor";
import { CustomRouteReuseStrategy } from "./app/common/models/CustomRouteReuseStrategy";
import { JwtModule, JWT_OPTIONS, JwtHelperService } from "@auth0/angular-jwt";
import { importProvidersFrom } from "@angular/core";
import { RouteReuseStrategy, RouterModule } from "@angular/router";
import { routes } from "./app/router";
import { MatDialogModule } from "@angular/material/dialog";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule, DatePipe } from "@angular/common";
import { NgxEchartsModule } from "ngx-echarts";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { DictionaryTranslationPipe } from "./app/common/pipes/dictionary-translation.pipe";
import { MatTableModule } from "@angular/material/table";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Function to load the config.json before app initialization
function loadAppConfig(http: HttpClient) {
  return () => {
    return http.get('/assets/config.json')
      .pipe(
        catchError(() => {
          console.error('Could not load config.json');
          return of({});
        })
      )
      .toPromise()
      .then(config => {
        Object.assign(environment, config);
      });
  };
}
const appConfig = (window as any).appConfig || {};
enableProdMode();

bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    {
      provide: 'baseUrl',
      useFactory: () => appConfig?.baseURL || environment.baseURL
    },
    {
      provide: 'checkSignalrDevApiUrl',
      useFactory: () => (window as any).appConfig?.checkSignalrDevApiUrl || environment.checkSignalrDevApiUrl
    },
    {
      provide: 'allowedDomainsDEVApi',
      useFactory: () => (window as any).appConfig?.allowedDomainsDEVApi || environment.allowedDomainsDEVApi
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadAppConfig,
      deps: [HttpClient],
      multi: true
    },
    importProvidersFrom(
      NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
      MatDialogModule,
      CommonModule,
      RouterModule.forRoot(routes),
      JwtModule.forRoot({
        config:{
          tokenGetter:()=>{
            return localStorage.getItem("accessToken")
          },
          allowedDomains:[environment.allowedDomainsDEVApi]
        }
      }),
      RouterModule.forRoot(routes),
      MatDialogModule,
      BrowserModule,
      BrowserAnimationsModule,
      NgbModule,
      NgxSpinnerModule,
      ToastrModule.forRoot({
        closeButton: true,
        progressBar: true
      }),
      DatePipe,

      JwtHelperService,
      DictionaryTranslationPipe,
      MatTableModule,
      NgxEchartsModule.forRoot({
        echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
      }),
    ),
    // Register APP_INITIALIZER to load config before app starts

  ]
});
